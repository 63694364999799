/* This file is for your main application css. */

.clear {
    clear:both;
}

.pick-col {
    width: 25%;
}

.team-logo-index {
    height:60px;
}

.spread-positive {
    color: green;
}

.spread-negative {
    color: red;
}

.team-possession {
    width: 20px;
}

.prof-pic-small {
    width: 50px;
    height: 50px;
    border-radius: 25px;
}

.user-prof-pic-text {
    color: black;
}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
    h3.picks-header {
        text-align: center;
        font-size: 3vw;
        margin-bottom: 25px;
    }

    .team-info {
        border: 1px solid gray;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        font-style: italic;
        color: gray;
        font-weight: bold;
    }

    .team-name-show {
        font-size: 2.0rem;
    }

    .team-logo {
        height:150px;
    }

    .user-stats {
        margin-bottom: 50px;
    }

    .week-btn {
        font-size:20pt;
    }

    .user-prof-pic-text {
        text-align: center;
        margin-top: 10px;
        font-size: 1vw;
    }

    .prof-pic {
        width: 70%;
        height: 70%;
        border: 1px solid gray;
        border-radius: 50%;
        margin-left: 15%;
    }

    .user-picks {
        margin: 0px 25%;
    }

    .pick-prof-pic {
        width: 40%;
        border-radius: 50%;
        float: left;
        margin-top: 5px;
        margin: 5px 5%;
        border: 1px solid gray;
    }

    .matchup-vs {
        font-size:1.2vw;
        margin-top: 30px;
    }

    .game-info {
        margin-top: 35%;
        font-size:1.0vw;
    }

    .game-status {
        margin-top: 30px;
        height: 45px;
        font-size: 1.0vw;
        border-radius: 10px;
        width: 60%;
    }

    .edit-spread {
        width: 30%;
        text-align: center;
        margin-top: 25px;
    }

    .spread {
        font-size: 1.2vw;
        margin-top: 10px;
    }

    .asterisk-text {
        font-size: 1.0vw;
    }

    .team-logo-pick {
        margin-top:10px;
        width:60%;
        border-radius: 50%;
    }

    .game-score {
        font-weight: bold;
        font-size: 2vw;
        margin: 15px auto;
        border: 1px solid #dee2e6;
        border-radius: 10px;
        width: 40%;
    }

    th.pick-col {
        font-size: 1vw;
    }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
    h3.picks-header {
        text-align: center;
        font-size: 6vw;
        margin-bottom: 25px;
    }

    .team-info {
        border: 1px solid gray;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        font-style: italic;
        color: gray;
        font-weight: bold;
        font-size: 2.5vw;
    }

    .team-name-show {
        font-size: 1.1rem;
        margin-left: 15px;
    }

    .team-logo {
        height:100px;
    }

    .team-record {
        font-size: 2.5vw;
    }

    .user-stats {
        font-size: 2.5vw;
        margin-bottom: 50px;
    }

    .user-prof-pic-text {
        text-align: center;
        margin-top: 10px;
    }

    .prof-pic {
        width: 100%;
        height: 100%;
        border: 1px solid gray;
        border-radius: 50%;
    }

    .pick-prof-pic {
        width: 40%;
        border-radius: 50%;
        float: left;
        margin-top: 5px;
        margin-right: 5px;
        border: 1px solid gray;
    }

    .matchup-vs {
        font-size:2.5vw;
        margin-top: 10px;
    }

    .game-info {
        margin-top: 30px;
        font-size:2.5vw;
    }

    .game-status {
        margin-top: 10px;
        height: 40px;
        font-size: 3.0vw;
        border-radius: 10px;
    }

    .edit-spread {
        width: 50%;
        text-align: center;
        margin-top: 10px;
    }

    .spread {
        font-size: 3.0vw;
        margin-top: 10px;
    }

    .asterisk-text {
        font-size: 2.5vw;
    }

    .team-logo-pick {
        width:100%;
        border-radius: 50%;
    }

    .game-score {
        font-weight: bold;
        font-size: 4vw;
        margin: 15% auto;
        border: 1px solid #dee2e6;
        border-radius: 10px;
        width: 60%;
    }
}

.away-team-info {
    float: left;
}

.home-team-info {
    float: right;
}

.game-score-won {
    border: 2px solid lightgreen;
}

.user-prof-pic {
    float:left;
    margin-right: 2.5%;
    margin-left: 2.5%;
    margin-top: 20px;
    margin-bottom: 40px;
    width: 20%;
}

.user-prof-pics {
    margin:0 auto;
    text-align: center;
    justify-content: center;
}

.user-has-asterisk {
    text-align: center;
}

.asterisk-text-not-picked {
    opacity: 0.2;
    
}

.asterisk-text-not-picked:hover {
    opacity: 1.0;
    font-weight: bold;
    cursor: pointer;
}

.asterisk-text-picked {
    opacity: 1.0;
    font-weight: bold;
    color: #E74C3C;
}

.asterisk_section {
    margin-top: 15px;
}

#tie-breaker {
    text-align: center;
}

#total-points {
    width: 60px;
    height: 60px;
    text-align: center;
}

#tie-breaker-text {
    font-size: 14pt;
}

#tie-breaker-success {
    display: none;
    width: 300px;
    margin: 20px auto;
}

.user-name-stats {
    font-weight: bold;
    font-size:2vw;
}

.positive-money {
    color:green;
}

.negative-money {
    color:red;
}

th {
    text-align:center;
}

td {
    text-align:center;
}